import React, { useState, useEffect } from 'react';
import { Button } from "@material-tailwind/react";

export function CTAButton({ onClick, text, description, variant }) {

  const [selectedVariant, setSelectedVariant] = useState(variant);

  useEffect(() => {
    setSelectedVariant(variant);
  }, [variant]);

  const buttonClass = selectedVariant === 'highlight'
    ? 'bg-gradient-to-r from-green-500 to-green-700 text-white'
    : 'bg-gradient-to-r from-military-green-500 to-slate-blue-500 text-gray-300';

  return (
    <div className="mt-10 flex flex-col items-center justify-center opacity-0 animate-[fadeIn_0.5s_ease-out_0.6s_forwards]">
      <Button 
        onClick={onClick}
        className={`w-full sm:w-auto ${buttonClass} flex items-center justify-center gap-2 transform hover:scale-[1.02] transition-all duration-200 shadow-lg hover:shadow-xl px-8 py-4`}
        size="lg"
      >
        {text} <span className="text-lg group-hover:translate-x-1 transition-transform">→</span>
      </Button>
      <div className="mt-3 text-center">
        <p className="text-sm text-military-green-700 font-medium">
          {description}
        </p>
      </div>
    </div>
  );
} 