import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from "@material-tailwind/react";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ErrorPage from './components/ErrorPage';
import EngineProject from './components/EngineProject';
import Dashboard from './components/Dashboard';
import About from './components/About';
import Goals from './components/Goals';
import Quizzes from './components/Quizzes';
import QuizEditor from './components/quiz/QuizEditor';
import Agents from './components/Agents';
import Agent from './components/agents/Agent';
import Engines from './components/Engines';
import { RegistrationForm } from './components/RegistrationForm';
import { EngineGenerator } from './components/EngineGenerator';
import { AgentGenerator } from './components/agents/AgentGenerator';
import Contact from './components/Contact';
import Products from './components/Products';
import News from './components/News';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import License from './components/License';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Affiliates from './components/Affiliates';
import Businesses from './components/Businesses';
import Invoices from './components/invoices/Invoices';
import CreateInvoice from './components/invoices/CreateInvoice';
import Invoice from './components/invoices/Invoice';
import Customers from './components/customers/Customers';
import CreateCustomer from './components/customers/CreateCustomer';
import Customer from './components/customers/Customer';
import Requests from './components/requests/Requests';
import Request from './components/requests/Request';
import CreateRequest from './components/requests/CreateRequest';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Pricing from './components/Pricing';
import Settings from './components/Settings';
import Profile from './components/Profile';
import Help from './components/Help';
import ErrorBoundary from './components/ErrorBoundary';
import CreateSubscription from './components/subscriptions/CreateSubscription';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about-recommengine",
    element: <About />,
    errorElement: <ErrorPage />,
  },
  {
    path: "engines/:id",
    element: <EngineProject />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/agents",
    element: <Agents />,
    errorElement: <ErrorPage />,
  },
  {
    path: "agents/:id",
    element: <Agent />,
  },
  {
    path: "/engines",
    element: <Engines refreshEngines={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/engines/new",
    element: <EngineGenerator />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/agents/new",
    element: <AgentGenerator />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/register",
    element: <RegistrationForm />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-in",
    element: <RegistrationForm signIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-out",
    element: <RegistrationForm signOut />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-ios-apps",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/view-android-apps",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/license",
    element: <License />,
    errorElement: <ErrorPage />,
  },
  // {
  //   path: "/contact-us",
  //   element: <Dashboard />,
  //   errorElement: <ErrorPage />,
  // },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/goals",
    element: <Goals />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/quizzes",
    element: <Quizzes />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/new-quiz",
    element: <QuizEditor />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contact",
    element: <Contact />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/products",
    element: <Products />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/news",
    element: <News />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/services",
    element: <Services />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/affiliates",
    element: <Affiliates />,
    errorElement: <ErrorPage />
  },
  {
    path: "/businesses",
    element: <Businesses />,
    errorElement: <ErrorPage />
  },
  {
    path: "/businesses/:id",
    element: <AboutUs />,
    errorElement: <ErrorPage />
  },
  {
    path: "/invoices",
    element: <Invoices />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/invoices/new",
    element: <CreateInvoice />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/invoices/new/:customerId",
    element: <CreateInvoice />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/invoices/:id",
    element: <Invoice />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/customers",
    element: <Customers />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/customers/new",
    element: <CreateCustomer />,
    errorElement: <ErrorPage />,
  },
  
  {
    path: "/customers/:id",
    element: <Customer />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/requests",
    element: <Requests />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/requests/:id",
    element: <Request />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/requests/new",
    element: <CreateRequest />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/requests/edit/:id",
    element: <CreateRequest />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/invoices/edit/:id",
    element: <CreateInvoice />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/customers/edit/:id",
    element: <CreateCustomer />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/engines/archived",
    element: <Engines archivedOnly={true} />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/settings",
    element: <Settings />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/help",
    element: <Help />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/subscription/new",
    element: <CreateSubscription />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/subscription/edit/:id",
    element: <CreateSubscription />,
    errorElement: <ErrorPage />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <ErrorBoundary>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </AuthProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
