import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { collection, addDoc, doc, getDoc, setDoc, getDocs, query, where, runTransaction } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig';
import General from '../../layouts/General';
import RegularButton from '../buttons/RegularButton';
import { Input, Typography, Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import CustomerSearch from '../customers/CustomerSearch';

const invoiceStatuses = [
  "Draft",
  "Pending",
  "Sent",
  "Ready",
  "Paid",
  "Partially Paid",
  "Overdue",
  "Cancelled",
  "Written Off",
  "Archived"
];

const getPresetDate = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date.toISOString().split('T')[0];
};

const createInvoice = async (userId, invoiceData) => {
  try {
    const newInvoiceId = await runTransaction(db, async (transaction) => {
      const configRef = doc(db, 'recommengine', 'users', userId, 'config');
      const configDoc = await transaction.get(configRef);
      
      let nextInvoiceNumber = 1;
      if (configDoc.exists()) {
        nextInvoiceNumber = (configDoc.data().lastInvoiceNumber || 0) + 1;
      }

      transaction.set(configRef, { lastInvoiceNumber: nextInvoiceNumber }, { merge: true });

      const invoiceRef = doc(collection(db, 'recommengine', 'users', userId, 'data', 'invoices'));
      const formattedInvoiceNumber = `INV-${String(nextInvoiceNumber).padStart(6, '0')}`;
      
      transaction.set(invoiceRef, {
        ...invoiceData,
        invoiceNumber: formattedInvoiceNumber,
        date: new Date().toISOString(),
      });

      return invoiceRef.id;
    });

    return newInvoiceId;
  } catch (error) {
    console.error("Error adding invoice: ", error);
    throw error;
  }
};

const updateInvoice = async (userId, invoiceId, invoiceData) => {
  try {
    await setDoc(
      doc(db, `recommengine/users/${userId}/data/invoices`, invoiceId),
      {
        ...invoiceData,
        lastUpdated: new Date().toISOString(),
      },
      { merge: true }
    );
    return invoiceId;
  } catch (error) {
    console.error("Error updating invoice: ", error);
    throw error;
  }
};

const CreateInvoice = () => {
  const { customerId, id: invoiceId } = useParams();
  const [invoice, setInvoice] = useState({
    customerName: '',
    customerId: customerId || '',
    amount: '',
    dueDate: '',
    description: '',
    email: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'United States',
    status: 'Draft',
    invoiceNumber: '',
  });
  const [customerInfo, setCustomerInfo] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });

    // Fetch customer details if customerId is present
    const fetchCustomerDetails = async () => {
      if (customerId && currentUser) {
        try {
          const customerRef = doc(db, `recommengine/users/${currentUser.uid}/data/customers`, customerId);
          const customerSnap = await getDoc(customerRef);
          if (customerSnap.exists()) {
            const customerData = customerSnap.data();
            setCustomerInfo(customerData);
            setInvoice(prevInvoice => ({
              ...prevInvoice,
              customerId: customerId,
              customerName: customerData.firstName + ' ' + customerData.lastName,
              email: customerData.email || '',
              address: customerData.address || '',
              city: customerData.city || '',
              state: customerData.state || '',
              zipCode: customerData.zipCode || '',
              country: customerData.country || 'United States',
            }));
          }
        } catch (error) {
          console.error("Error fetching customer details: ", error);
        }
      }
    };

    fetchCustomerDetails();

    // Add invoice fetching logic for edit mode
    const fetchInvoice = async () => {
      if (invoiceId && currentUser) {
        setIsEditing(true);
        try {
          const invoiceRef = doc(db, `recommengine/users/${currentUser.uid}/data/invoices`, invoiceId);
          const invoiceSnap = await getDoc(invoiceRef);
          if (invoiceSnap.exists()) {
            const invoiceData = invoiceSnap.data();
            setInvoice({
              ...invoice,
              ...invoiceData,
              amount: invoiceData.amount || '',
              dueDate: invoiceData.dueDate ? invoiceData.dueDate.split('T')[0] : '',
              description: invoiceData.description || '',
            });
          }
        } catch (error) {
          console.error("Error fetching invoice: ", error);
        }
      }
    };

    fetchInvoice();

    return () => unsubscribe();
  }, [customerId, currentUser, invoiceId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInvoice(prevInvoice => ({
      ...prevInvoice,
      [name]: value
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (!invoice.customerName.trim()) {
      newErrors.customerName = 'Customer name is required';
    }
    
    if (!invoice.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(invoice.email)) {
      newErrors.email = 'Please enter a valid email';
    }
    
    if (!invoice.amount || invoice.amount <= 0) {
      newErrors.amount = 'Please enter a valid amount';
    }
    
    if (!invoice.dueDate) {
      newErrors.dueDate = 'Due date is required';
    }

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) {
      setOpenErrorDialog(true);
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      console.error('No user logged in');
      return;
    }

    if (!validateForm()) {
      return;
    }

    // Only show confirmation if not saving as draft and sending for the first time
    if (!isEditing && invoice.status !== 'Draft') {
      const confirmed = window.confirm(
        'Are you sure you want to create and send this invoice now? The customer will be notified immediately.'
      );
      if (!confirmed) return;
    }

    try {
      if (isEditing) {
        await updateInvoice(currentUser.uid, invoiceId, invoice);
      } else {
        await createInvoice(currentUser.uid, invoice);
      }
      navigate('/invoices');
    } catch (error) {
      console.error('Error saving invoice:', error);
    }
  };

  const handleSaveAsDraft = async (e) => {
    e.preventDefault();
    setInvoice(prev => ({ ...prev, status: 'Draft' }));
    
    try {
      if (isEditing) {
        await updateInvoice(currentUser.uid, invoiceId, { ...invoice, status: 'Draft' });
      } else {
        await createInvoice(currentUser.uid, { ...invoice, status: 'Draft' });
      }
      navigate('/invoices');
    } catch (error) {
      console.error('Error saving draft:', error);
    }
  };

  const handleCustomerSelect = (customer) => {
    if (!customer) {
      setInvoice(prev => ({
        ...prev,
        customerId: '',
        customerName: '',
        email: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        country: 'United States',
      }));
      setCustomerInfo(null);
      return;
    }

    setInvoice(prev => ({
      ...prev,
      customerId: customer.id,
      customerName: `${customer.firstName} ${customer.lastName}`,
      email: customer.email || '',
      address: customer.address || '',
      city: customer.city || '',
      state: customer.state || '',
      zipCode: customer.zipCode || '',
      country: customer.country || 'United States',
    }));
    setCustomerInfo(customer);
  };

  const handleClearCustomer = () => {
    setCustomerInfo(null);
    setInvoice(prev => ({
      ...prev,
      customerId: '',
      customerName: '',
      email: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      country: 'United States',
    }));
  };

  const handlePresetDate = (days) => {
    setInvoice(prev => ({
      ...prev,
      dueDate: getPresetDate(days)
    }));
  };

  const handleArchive = async (e) => {
    e.preventDefault();
    setOpenArchiveDialog(true);
  };

  const confirmArchive = async () => {
    try {
      await updateInvoice(currentUser.uid, invoiceId, { ...invoice, status: 'Archived' });
      setOpenArchiveDialog(false);
      navigate('/invoices');
    } catch (error) {
      console.error('Error archiving invoice:', error);
    }
  };

  const customerSearchSection = (
    <>
      {!customerId && !customerInfo && (
        <CustomerSearch 
          onCustomerSelect={handleCustomerSelect}
          currentUser={currentUser}
        />
      )}
      
      {(customerId || customerInfo) && (
        <div className="bg-gray-50 p-4 rounded">
          <div className="flex justify-between items-start mb-2">
            <Typography variant="h6" color="blue-gray" className="text-sm font-medium">
              Selected Customer
            </Typography>
            <Button
              size="sm"
              variant="text"
              color="red"
              onClick={handleClearCustomer}
              className="p-2"
            >
              Clear
            </Button>
          </div>
          <p><strong>Name:</strong> {customerInfo?.firstName} {customerInfo?.lastName}</p>
          <p><strong>Email:</strong> {customerInfo?.email}</p>
          <p><strong>Address:</strong> {customerInfo?.address}</p>
          <p><strong>City:</strong> {customerInfo?.city}</p>
          <p><strong>State:</strong> {customerInfo?.state}</p>
          <p><strong>Zip Code:</strong> {customerInfo?.zipCode}</p>
          <p><strong>Country:</strong> {customerInfo?.country}</p>
        </div>
      )}
    </>
  );

  const errorDialog = (
    <Dialog open={openErrorDialog} handler={() => setOpenErrorDialog(false)}>
      <DialogHeader>Form Validation Errors</DialogHeader>
      <DialogBody>
        <ul className="list-disc pl-4">
          {Object.entries(errors).map(([field, error]) => (
            <li key={field} className="text-red-500 mb-2">
              {error}
            </li>
          ))}
        </ul>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => setOpenErrorDialog(false)}>Close</Button>
      </DialogFooter>
    </Dialog>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <General title={isEditing ? "Edit Invoice" : "Create New Invoice"}>
      <div className='flex flex-col w-full px-2 sm:px-4 lg:px-6 max-w-[calc(100vw-3rem)]'>
        <div className='bg-white shadow-md rounded px-2 sm:px-4 lg:px-6 pt-4 sm:pt-6 pb-6 sm:pb-8 mb-4'>
          <form onSubmit={handleSubmit}>
            <div className='flex flex-col 2xl:grid 2xl:grid-cols-2 2xl:gap-x-4'>
              {/* Add the customerSearchSection here, before the Customer Information Section */}
              {customerSearchSection}
              
              {/* Customer Information Section */}
              <div className='mb-6 lg:mb-0'>
                <div>
                  <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                    Customer Information
                  </Typography>
                  
                  {customerId && customerInfo ? (
                    <div className="bg-gray-50 p-4 rounded">
                      <p><strong>Name:</strong> {customerInfo.firstName} {customerInfo.lastName}</p>
                      <p><strong>Email:</strong> {customerInfo.email}</p>
                      <p><strong>Address:</strong> {customerInfo.address}</p>
                      <p><strong>City:</strong> {customerInfo.city}</p>
                      <p><strong>State:</strong> {customerInfo.state}</p>
                      <p><strong>Zip Code:</strong> {customerInfo.zipCode}</p>
                      <p><strong>Country:</strong> {customerInfo.country}</p>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      <div className="space-y-4 sm:space-y-6">
                        <Input
                          size="lg"
                          label="Customer Name"
                          name="customerName"
                          value={invoice.customerName}
                          onChange={handleChange}
                          required
                          className="w-full"
                          error={errors.customerName}
                        />
                        {errors.customerName && (
                          <p className="text-red-500 text-xs">{errors.customerName}</p>
                        )}
                        <Input
                          size="lg"
                          label="Customer Email"
                          name="email"
                          type="email"
                          value={invoice.email}
                          onChange={handleChange}
                          required
                          className="w-full"
                          error={errors.email}
                        />
                        <Input
                          size="lg"
                          label="Street Address"
                          name="address"
                          value={invoice.address}
                          onChange={handleChange}
                          className="w-full"
                        />
                      </div>
                      
                      <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                        <Input
                          size="lg"
                          label="City"
                          name="city"
                          value={invoice.city}
                          onChange={handleChange}
                          className="w-full"
                        />
                        <Input
                          size="lg"
                          label="State"
                          name="state"
                          value={invoice.state}
                          onChange={handleChange}
                          className="w-full"
                        />
                      </div>
                      
                      <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                        <Input
                          size="lg"
                          label="Zip Code"
                          name="zipCode"
                          value={invoice.zipCode}
                          onChange={handleChange}
                          className="w-full"
                        />
                        <Input
                          size="lg"
                          label="Country"
                          name="country"
                          value={invoice.country}
                          onChange={handleChange}
                          className="w-full"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* Invoice Details Section */}
              <div className='mb-6 lg:mb-0'>
                <div>
                  <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                    Invoice Details
                  </Typography>
                  <div className="space-y-4">
                    {invoice.invoiceNumber && (
                      <div className="p-3 bg-gray-50 rounded">
                        <Typography variant="small" className="font-medium">
                          Invoice Number: {invoice.invoiceNumber}
                        </Typography>
                      </div>
                    )}
                    <Input
                      size="lg"
                      label="Amount"
                      name="amount"
                      type="number"
                      value={invoice.amount}
                      onChange={handleChange}
                      required
                      className="w-full"
                      error={errors.amount}
                    />
                    <div className="relative">
                      <Input
                        size="lg"
                        label="Due Date"
                        name="dueDate"
                        type="date"
                        value={invoice.dueDate}
                        onChange={handleChange}
                        required
                        className="w-full"
                      />
                      <div className="flex flex-wrap gap-2 mt-2">
                        <button
                          type="button"
                          onClick={() => handlePresetDate(0)}
                          className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded"
                        >
                          Today
                        </button>
                        <button
                          type="button"
                          onClick={() => handlePresetDate(7)}
                          className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded"
                        >
                          1 Week
                        </button>
                        <button
                          type="button"
                          onClick={() => handlePresetDate(14)}
                          className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded"
                        >
                          2 Weeks
                        </button>
                        <button
                          type="button"
                          onClick={() => handlePresetDate(30)}
                          className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded"
                        >
                          30 Days
                        </button>
                        <button
                          type="button"
                          onClick={() => handlePresetDate(60)}
                          className="text-xs px-2 py-1 bg-gray-100 hover:bg-gray-200 rounded"
                        >
                          60 Days
                        </button>
                      </div>
                    </div>
                    <Input
                      size="lg"
                      label="Description"
                      name="description"
                      value={invoice.description}
                      onChange={handleChange}
                      className="w-full"
                    />
                    <select
                      className="w-full p-2 sm:p-3 border border-gray-300 rounded-lg focus:border-blue-500 text-sm sm:text-base"
                      name="status"
                      value={invoice.status}
                      onChange={handleChange}
                      required
                    >
                      {invoiceStatuses.map((status) => (
                        <option key={status} value={status}>{status}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center gap-2 mt-6">
              <RegularButton 
                onClick={handleSubmit} 
                type="submit" 
                text={isEditing ? "Update Invoice" : "Create Invoice"}
                variant="black"
              />
              {!isEditing && (
                <button
                  onClick={handleSaveAsDraft}
                  className="text-gray-600 underline cursor-pointer p-1"
                >
                  Save as Draft
                </button>
              )}
              {isEditing && invoice.status !== 'Archived' && (
                <button
                  onClick={handleArchive}
                  className="text-red-600 underline cursor-pointer p-1"
                >
                  Archive Invoice
                </button>
              )}
              <button
                onClick={() => navigate('/invoices')}
                className="text-gray-600 underline cursor-pointer p-1"
              >
                Back to Invoices
              </button>
            </div>
          </form>
        </div>
      </div>
      
      {errorDialog}
      
      <Dialog open={openArchiveDialog} handler={() => setOpenArchiveDialog(false)}>
        <DialogHeader>Confirm Archive</DialogHeader>
        <DialogBody>
          Are you sure you want to archive this invoice? This action can be reversed later.
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="gray"
            onClick={() => setOpenArchiveDialog(false)}
            className="mr-1"
          >
            Cancel
          </Button>
          <Button variant="gradient" color="red" onClick={confirmArchive}>
            Confirm Archive
          </Button>
        </DialogFooter>
      </Dialog>
    </General>
  );
};

export default CreateInvoice;
