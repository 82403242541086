import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button, Checkbox, Typography, Input } from "@material-tailwind/react";
import { getFirestore, collection, addDoc, query, where, getDocs, doc, getDoc, } from "firebase/firestore";

import General from "../layouts/General";
import EngineProjectContent from "./engines/EngineProjectContent";

import { useAuth } from '../contexts/AuthContext';

import { db } from '../FirebaseConfig'
import RegularButton from "./buttons/RegularButton";

import LoadingIndicator from "./LoadingIndicator";

import { FIRESTORE_PATHS, getCollectionPath } from '../config/firestore';

const getEngine = async (id, userId) => {
    const docRef = doc(db, getCollectionPath(FIRESTORE_PATHS.ENGINES(userId)), id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
};

function EngineProject() {
    const { currentUser } = useAuth();
    const { id } = useParams();
    const [ myEngine, setMyEngine ] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadEngine = async () => {
            if (currentUser) {
                try {
                    const engine = await getEngine(id, currentUser.uid);
                    setMyEngine(engine);
                } catch (error) {
                    console.error("Error loading engine:", error);
                }
            }
            setLoading(false);
        };

        loadEngine();
    }, [currentUser, id]);

    if (!currentUser) {
        return (
            <General title="">
                <div className="flex flex-col items-center justify-center p-8 space-y-4">
                    <Typography variant="h4" className="text-military-green-800">
                        Please sign in to view this engine
                    </Typography>
                    <div className="space-x-4">
                        <Link to="/register?signin=true" className="text-military-green-500 hover:text-military-green-700 font-medium">
                            Sign In
                        </Link>
                        <span className="text-military-green-300">|</span>
                        <Link to="/register" className="text-military-green-500 hover:text-military-green-700 font-medium">
                            Sign Up
                        </Link>
                    </div>
                </div>
            </General>
        );
    }

    if (loading) {
        return (
            <General title="Engine Details">
                <LoadingIndicator />
            </General>
        );
    }

    return (
        <General title={myEngine ? myEngine.name : ""}>
            <EngineProjectContent myEngine={myEngine} id={id} currentUser={currentUser} />
        </General>
    )
}

export default EngineProject;
