import React, { useState, useEffect, useRef } from 'react'
import {
    Button,
    Checkbox,
    Typography,
    Input,
    // List,
    // ListItem,
    Card,
    // Carousel,
    // CardBody,
    // Tabs,
    // TabsHeader,
    // TabsBody,
    // Tab,
    // TabPanel,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
} from "@material-tailwind/react";
// import { PlayCircleIcon, PauseCircleIcon, Cog6ToothIcon } from "@heroicons/react/24/solid";

// import { getDatabase, ref as databaseRef, get, set, push } from "firebase/database";
import { getStorage, ref as storageRef, getDownloadURL, deleteObject } from "firebase/storage";
import {
    doc,
    getDoc,
    updateDoc,
    deleteDoc,
    collection,
    query,
    getDocs,
    setDoc
} from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';

import LoadingIndicator from '../LoadingIndicator';
import RegularButton from '../buttons/RegularButton';
// import CameraSection from './CameraSection';
// import CalendarSection from './CalendarSection';
// import MapSection from './MapSection';
// import ChatSection from './ChatSectionContent';
import EngineTabContent from './EngineTabContent';
import EngineStatsSection from './EngineStatsSection';
import SpeechControl from './SpeechControl';
import { FIRESTORE_PATHS, getCollectionPath } from '../../config/firestore';

import {
    app,
    db,
    firebaseConfig
} from '../../FirebaseConfig';
import Recommengine from '../Recommengine';

const storage = getStorage(app);
const functions = getFunctions(app);

// Add this constant at the top of the file, outside the component
const FEATURE_ORDER = ['map', 'camera', 'calendar', 'chat'];


const getEngine = async (id, userId) => {
    const docRef = doc(db, getCollectionPath(FIRESTORE_PATHS.ENGINES(userId)), id);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
};

export default function EngineProjectContent({ myEngine, id, currentUser }) {
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [enginePhotos, setEnginePhotos] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [mapPositions, setMapPositions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [idToken, setIdToken] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
    const [openUnarchiveDialog, setOpenUnarchiveDialog] = useState(false);
    const [activeTab, setActiveTab] = useState("Map");
    const [showStats, setShowStats] = useState(true);
    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [sharedUsers, setSharedUsers] = useState([]);
    const [newSharedUser, setNewSharedUser] = useState('');
    const [newSharedUserEmail, setNewSharedUserEmail] = useState('');
    const [isSharedUser, setIsSharedUser] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            if (currentUser && myEngine) {
                setLoading(true);
                try {
                    const token = await currentUser.getIdToken();
                    setIdToken(token);
                    setIsPublic(myEngine.isPublic || false);
                    await Promise.all([
                        getEngineCalendarEvents(),
                        getEngineChatMessages(currentUser.uid),
                        getEnginePositions(),
                        getEnginePhotos()
                    ]);
                } catch (error) {
                    console.error("Error loading engine data:", error);
                }
                setLoading(false);
            }

            if (currentUser && id && !myEngine) {
                console.log('check if I am a shared user')
                // getEngineChatMessages("UfkIHeAZ5Vcu1shfxw8vCBUNfPb2");
                // myEngine = await getEngine(id, currentUser.uid);
                setIsSharedUser(true);
                setLoading(false);
            }
        };

        console.log('loading data');
        try {
            loadData();
        } catch (error) {
            console.error('Error loading data:', error);
        }
    }, [currentUser, myEngine]);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setShowStats(scrollPosition < 100); // Hide stats after scrolling 100px
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const loadSharedUsers = async () => {
            if (currentUser && id) {
                try {
                    const sharedWithRef = collection(db, 'recommengine', 'users', currentUser.uid, 'data', 'engines', id, 'sharedWith');
                    const snapshot = await getDocs(sharedWithRef);
                    const users = {};
                    snapshot.forEach(doc => {
                        users[doc.id] = {
                            active: doc.data().active,
                            email: doc.data().email
                        };
                    });
                    setSharedUsers(users);
                } catch (error) {
                    console.error("Error loading shared users:", error);
                    setSharedUsers({});
                }
            }
        };
        
        loadSharedUsers();
    }, [currentUser, id]);

    const retrieveEnginePhotos = async (id) => {
        if (!currentUser) {
            console.error("User not authenticated");
            return;
        }
        console.log('Going to retrieve photos for engine:', id);

        try {
            const engineDocRef = doc(db, `recommengine/users/${currentUser.uid}/data/engines`, id);
            const engineDocSnap = await getDoc(engineDocRef);
            
            if (engineDocSnap.exists()) {
                const engineData = engineDocSnap.data();
                const photos = engineData.images || [];
                if (!storage) {
                    console.error("Firebase storage is not initialized");
                    return [];
                }

                const processedPhotos = await Promise.all(photos.map(async (photo) => {
                    if (photo.startsWith('images/')) {
                        try {
                            const photoRef = storageRef(storage, photo);
                            const url = await getDownloadURL(photoRef);
                            return { id: photo, url: url };
                        } catch (error) {
                            console.error("Error getting download URL for photo:", photo, error);
                            return null;
                        }
                    } else {
                        return { id: photo, url: photo };
                    }
                }));

                const validPhotos = processedPhotos.filter(photo => photo !== null);
                setEnginePhotos(validPhotos);
                
                console.log('Photos retrieved:', validPhotos);
                return validPhotos;
            } else {
                console.log('No such engine document!');
                return [];
            }
        } catch (error) {
            console.error("Error fetching photos from Firestore:", error);
            return [];
        }
    };

    const getEngineCalendarEvents = async () => {
        if (currentUser) {
            const calendarCollection = collection(db, "recommengine/users/" + currentUser.uid + "/data/engines/" + id + "/calendarEvents");
            const q = query(calendarCollection);
            const querySnapshot = await getDocs(q);
            const events = [];
            querySnapshot.forEach((doc) => {
                const eventData = doc.data();
                const eventDate = new Date(eventData.year, eventData.month - 1, eventData.day);
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                const dayOfWeek = daysOfWeek[eventDate.getDay()];
                events.push({ ...eventData, dayOfWeek });
            });
            setCalendarEvents(events);
        } else {
            console.log('user not logged in');
        }
    };

    const getEnginePhotos = async () => {
        if (currentUser) {
            await retrieveEnginePhotos(id);
        }
    };
    const getEnginePositions = async () => {
        if (currentUser) {
            const positionsCollection = collection(db, "recommengine/users/" + currentUser.uid + "/data/engines/" + id + "/positions");
            const q = query(positionsCollection);
            const querySnapshot = await getDocs(q);
            const positions = [];
            querySnapshot.forEach((doc) => {
                const positionData = doc.data();
                if (positionData.timestamp && positionData.timestamp.toDate) {
                    positionData.timestamp = positionData.timestamp.toDate();
                }
                if (!positionData.title) {
                    positionData.title = `Location ${positions.length + 1}`;
                }
                positions.push(positionData);
            });
            
            // Sort positions by timestamp in descending order
            positions.sort((a, b) => b.timestamp - a.timestamp);
            
            setMapPositions(positions);
        } else {
            console.log('user not logged in');
        }
    };

    const sendMessage = async (message) => {
        console.log('sendMessage: ', message);
        // save in realtime db
        const idToken = await currentUser.getIdToken();
        const realtimeDbUrl = `${firebaseConfig.databaseURL}/recommengine/users/${currentUser.uid}/engines/${id}/messages.json?auth=${idToken}`;
        await fetch(realtimeDbUrl, {
            method: 'POST',
            body: JSON.stringify(message)
        });
    };

    const getEngineChatMessages = async (engineOwnerId) => {
        console.log('getEngineChatMessages: ', engineOwnerId);
        if (currentUser) {
            console.log('getEngineChatMessages: ', engineOwnerId);
            try {
                const idToken = await currentUser.getIdToken();
                const oneHourAgo = Date.now() - (60 * 60 * 1000);
                // Encode the orderBy parameter properly
                const orderByParam = JSON.stringify('"sentTime"');
                // const url = `${firebaseConfig.databaseURL}/recommengine/users/${engineOwnerId}/engines/${id}/messages.json?auth=${idToken}&orderBy=${orderByParam}&startAt=${oneHourAgo}`;
                const url = `${firebaseConfig.databaseURL}/recommengine/users/${engineOwnerId}/engines/${id}/messages.json?auth=${idToken}`;
                const response = await fetch(url);

                if (response.ok) {
                    const messages = await response.json();

                    if (messages) {
                        const formattedMessages = Object.keys(messages).map(key => ({
                            id: key,
                            ...messages[key]
                        }));
                        console.log('messages found: ', formattedMessages);
                        setChatMessages(formattedMessages);
                    } else {
                        console.log('no messages found');
                        setChatMessages([]);
                    }
                } else {
                    console.log('no messages found');
                    setChatMessages([]);
                }
            } catch (error) {
                console.error("Error fetching chat messages:", error);
                setChatMessages([]);
            }
        }
    };

    const deleteEngine = async () => {
        if (currentUser) {
            console.log('delete engine: ', myEngine.id);

            const engineRef = doc(db, "recommengine/users/" + currentUser.uid + "/data/engines", id);
            await deleteDoc(engineRef);
            console.log('engine deleted');

            window.location.href = '/engines';
        } else {
            console.log('user not logged in');
        }
    };

    const refreshEvents = async () => {
        // Fetch updated calendar events from Firebase
        const eventsRef = collection(db, `recommengine/users/${currentUser.uid}/data/engines/${id}/calendarEvents`);
        const snapshot = await getDocs(eventsRef);
        const events = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setCalendarEvents(events);
    };

    const handleArchive = async () => {
        const engineRef = doc(db, "recommengine/users/"+ currentUser.uid +"/data/engines", id);
        await updateDoc(engineRef, {
            archived: true
        });
        window.location.href = "/engines";
    };

    const handleUnarchive = async () => {
        const engineRef = doc(db, "recommengine/users/"+ currentUser.uid +"/data/engines", id);
        await updateDoc(engineRef, {
            archived: false
        });
        window.location.href = "/engines";
    };

    const handleStatsCardClick = (feature) => {
        // Set showContent to true to switch to app view
        setShowContent(true);
        
        let tabValue;
        switch(feature) {
            case 'map':
                tabValue = 'Map';
                break;
            case 'camera':
                tabValue = 'Camera';
                break;
            case 'calendar':
                tabValue = 'Calendar';
                break;
            case 'chat':
                tabValue = 'Chat';
                break;
            default:
                tabValue = feature.charAt(0).toUpperCase() + feature.slice(1);
        }
        setActiveTab(tabValue);
    };

    const deletePhoto = async (photoId) => {
        if (!currentUser) {
            console.error("User not authenticated");
            return;
        }

        try {
            // Create reference to the photo in Storage
            const photoRef = storageRef(storage, photoId);

            // Delete from Storage
            await deleteObject(photoRef);

            // Update the engine document to remove the photo reference
            const engineRef = doc(db, `recommengine/users/${currentUser.uid}/data/engines`, id);
            await updateDoc(engineRef, {
                images: enginePhotos
                    .filter(photo => photo.id !== photoId)
                    .map(photo => photo.id)
            });

            // Update local state
            setEnginePhotos(prevPhotos => prevPhotos.filter(photo => photo.id !== photoId));
            
            console.log('Photo deleted successfully');
        } catch (error) {
            console.error("Error deleting photo:", error);
            throw error;
        }
    };

    const handleVisibilityChange = async (event) => {
        const newIsPublic = event.target.checked;
        try {
            const engineRef = doc(db, "recommengine/users/" + currentUser.uid + "/data/engines", id);
            await updateDoc(engineRef, {
                isPublic: newIsPublic
            });
            setIsPublic(newIsPublic);
        } catch (error) {
            console.error("Error updating engine visibility:", error);
            setErrorMessage("Failed to update engine visibility");
        }
    };

    const handleAddSharedUser = async () => {
        console.log('handleAddSharedUser: ', newSharedUserEmail);
        if (!newSharedUserEmail.trim()) return;

        try {
            // Call the Firebase function to check if user exists
            console.log('checking if user exists: ', newSharedUserEmail);
            const checkUserIdFunction = httpsCallable(functions, 'checkUserId');
            const result = await checkUserIdFunction({ email: newSharedUserEmail });
            console.log('result: ', result);

            if (!result.data.exists) {
                setErrorMessage("User not found");
                return;
            }
            
            // Update Firestore
            const sharedUserRef = doc(db, 'recommengine/users', currentUser.uid, 'data', 'engines', id, 'sharedWith', result.data.userId);
            await setDoc(sharedUserRef, { 
                active: true,
                addedAt: new Date(),
                addedBy: currentUser.uid,
                email: newSharedUserEmail
            });

            // Update Realtime Database
            const idToken = await currentUser.getIdToken();
            const realtimeDbUrl = `${firebaseConfig.databaseURL}/recommengine/users/${currentUser.uid}/engines/${id}/sharedWith/${result.data.userId}.json?auth=${idToken}`;
            await fetch(realtimeDbUrl, {
                method: 'PUT',
                body: JSON.stringify({
                    active: true,
                    addedAt: new Date().toISOString(),
                    addedBy: currentUser.uid,
                    email: newSharedUserEmail
                })
            });
            
            setSharedUsers(prev => ({
                ...prev,
                [result.data.uid]: true
            }));
            setNewSharedUser('');
            
        } catch (error) {
            console.error("Error sharing engine:", error);
            setErrorMessage("Failed to share engine: " + error.message);
        }
    };

    const toggleUserAccess = async (uid) => {
        try {
            const sharedUserRef = doc(db, 'recommengine', 'users', currentUser.uid, 'data', 'engines', id, 'sharedWith', uid);
            const newStatus = !sharedUsers[uid];
            await updateDoc(sharedUserRef, { active: newStatus });
            
            setSharedUsers(prev => ({
                ...prev,
                [uid]: newStatus
            }));
        } catch (error) {
            console.error("Error updating user access:", error);
            setErrorMessage("Failed to update user access");
        }
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    if (!myEngine && !isSharedUser) {
        return (
            <div className='flex flex-col w-full h-full'>
                <Typography variant="h3">Engine Not Found</Typography>
            </div>
        );
    }

    return (
        <div className='w-full h-full'>
            <Recommengine currentUser={currentUser} engine={myEngine} sendMessage={sendMessage} chatMessages={chatMessages} />
            <div className='w-full min-h-screen flex flex-col pt-4 pb-8 md:pb-16'>
                {!showContent && !isSharedUser ? (
                    <EngineStatsSection 
                        myEngine={myEngine}
                        mapPositions={mapPositions}
                        enginePhotos={enginePhotos}
                        calendarEvents={calendarEvents}
                        chatMessages={chatMessages}
                        onFeatureClick={handleStatsCardClick}
                        showContent={showContent}
                        onToggleContent={() => setShowContent(!showContent)}
                        openSettingsDialog={openSettingsDialog}
                        setOpenSettingsDialog={setOpenSettingsDialog}
                    />
                ) : (
                    !isSharedUser ? (
                        <EngineTabContent 
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            myEngine={myEngine}
                            mapPositions={mapPositions}
                            enginePhotos={enginePhotos}
                            calendarEvents={calendarEvents}
                            chatMessages={chatMessages}
                            currentUser={currentUser}
                            id={id}
                            errorMessage={errorMessage}
                            openArchiveDialog={openArchiveDialog}
                            openUnarchiveDialog={openUnarchiveDialog}
                            setOpenArchiveDialog={setOpenArchiveDialog}
                            setOpenUnarchiveDialog={setOpenUnarchiveDialog}
                            handleArchive={handleArchive}
                            handleUnarchive={handleUnarchive}
                            getEnginePositions={getEnginePositions}
                            deletePhoto={deletePhoto}
                            getEnginePhotos={getEnginePhotos}
                            refreshEvents={refreshEvents}
                            getEngineChatMessages={getEngineChatMessages}
                            FEATURE_ORDER={FEATURE_ORDER}
                            onToggleContent={() => setShowContent(false)}
                        />
                    ) : (
                        <>
                            {/* <EngineSharedUserContent /> */}
                            <Typography variant="h3">Engine Shared with You</Typography>
                        </>
                    )
                )}
            </div>

            {/* Settings Dialog */}
            {!isSharedUser && <Dialog size="xl" open={openSettingsDialog} handler={() => setOpenSettingsDialog(false)}>
                <DialogHeader>Engine Settings</DialogHeader>
                <DialogBody divider className="h-[calc(100vh-200px)] overflow-y-auto">
                    <div className="space-y-6">
                        {errorMessage && (
                            <div className="bg-red-50 text-red-700 p-4 rounded-lg border border-red-200">
                                {errorMessage}
                            </div>
                        )}
                        <Card className="p-6 bg-military-green-50/30 border border-military-green-100">
                            <div className="flex flex-col gap-4">
                                <Typography variant="h6">General Settings</Typography>
                                <div className="flex flex-col gap-2">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <div className="flex-grow">
                                            <Input
                                                type="text"
                                                label="Engine Name"
                                                value={myEngine.name || ""}
                                                disabled
                                            />
                                        </div>
                                        <div className="md:self-end">
                                            <SpeechControl 
                                                text={myEngine.name} 
                                                currentUser={currentUser} 
                                                engineId={id}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <div className="flex-grow">
                                            <Input 
                                                type="text"
                                                label="Engine Purpose"
                                                value={myEngine.purpose || ""}
                                                disabled
                                            />
                                        </div>
                                        <div className="md:self-end">
                                            <SpeechControl 
                                                text={myEngine.purpose} 
                                                currentUser={currentUser} 
                                                engineId={id}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Typography variant="small">Engine Color</Typography>
                                    <input 
                                        type="color"
                                        value={myEngine.color || "#000000"}
                                        className="w-full h-10 cursor-pointer"
                                        onChange={(e) => {
                                            const engineRef = doc(db, "recommengine/users/"+ currentUser.uid +"/data/engines", id);
                                            updateDoc(engineRef, {
                                                color: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                {/* <div className="flex items-center gap-2">
                                    <Checkbox
                                        checked={isPublic}
                                        onChange={handleVisibilityChange}
                                        color="green"
                                    />
                                    <Typography variant="small">
                                        Make this engine public
                                    </Typography>
                                </div> */}
                                <div className="mt-6">
                                    <Typography variant="h6">Share Engine</Typography>
                                    <div className="flex gap-2 mb-4">
                                        {/* <Input
                                            type="text"
                                            label="User ID"
                                            value={newSharedUser}
                                            onChange={(e) => setNewSharedUser(e.target.value)}
                                        /> */}
                                        <Input
                                            type="text"
                                            label="User Email"
                                            value={newSharedUserEmail}
                                            onChange={(e) => setNewSharedUserEmail(e.target.value)}
                                        />
                                        <Button onClick={handleAddSharedUser}>
                                            Add User
                                        </Button>
                                    </div>
                                    
                                    {Object.entries(sharedUsers).length > 0 ? (
                                        <div className="space-y-2">
                                            <Typography variant="small" color="gray">Shared with:</Typography>
                                            {Object.entries(sharedUsers).map(([uid, userData]) => (
                                                <div key={uid} className="flex justify-between items-center">
                                                    <Typography variant="small">{userData.email}</Typography>
                                                    <Checkbox
                                                        checked={userData.active}
                                                        onChange={() => toggleUserAccess(uid)}
                                                        color="green"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <Typography variant="small" color="gray">No users shared yet</Typography>
                                    )}
                                </div>
                                <div className="mt-8">
                                    <Typography variant="h6" color="red" className="mb-4">
                                        Danger Zone
                                    </Typography>
                                    {!myEngine.archived ? (
                                        <RegularButton 
                                            text="Archive Engine" 
                                            filled={true}
                                            color="red"
                                            onClick={() => setOpenArchiveDialog(true)}
                                        />
                                    ) : (
                                        <RegularButton 
                                            text="Unarchive Engine" 
                                            filled={true}
                                            color="red"
                                            onClick={() => setOpenUnarchiveDialog(true)}
                                        />
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="gray"
                        onClick={() => setOpenSettingsDialog(false)}
                        className="mr-1"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="gradient"
                        color="red"
                        onClick={() => setOpenSettingsDialog(false)}
                    >
                        Save Changes
                    </Button>
                </DialogFooter>
            </Dialog>}
        </div>
    );
}
