import React, { useState, useEffect } from 'react';
import { Typography, Button } from "@material-tailwind/react";
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, auth } from '../../FirebaseConfig';
import LoadingIndicator from '../LoadingIndicator';
import General from '../../layouts/General';

function Customer() {
    const { id } = useParams();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [customerLoading, setCustomerLoading] = useState(true);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [archiveConfirmOpen, setArchiveConfirmOpen] = useState(false);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchCustomer = async () => {
            if (!user) return;

            setCustomerLoading(true);
            try {
                const customerRef = doc(db, `recommengine/users/${user.uid}/data/customers`, id);
                const customerSnap = await getDoc(customerRef);

                if (customerSnap.exists()) {
                    setCustomer({ id: customerSnap.id, ...customerSnap.data() });
                } else {
                    setCustomer(null);
                }
            } catch (error) {
                console.error("Error fetching customer: ", error);
                setCustomer(null);
            } finally {
                setCustomerLoading(false);
            }
        };

        if (user) {
            fetchCustomer();
        }
    }, [id, user]);

    const handleEdit = () => {
        // Navigate to edit page (you'll need to create this route)
        navigate(`/customers/edit/${id}`);
    };

    const handleArchive = () => {
        setArchiveConfirmOpen(true);
    };

    const confirmArchive = async () => {
        if (!user || !customer) return;
        
        try {
            const customerRef = doc(db, `recommengine/users/${user.uid}/data/customers`, id);
            await setDoc(customerRef, { ...customer, archived: true }, { merge: true });
            setArchiveConfirmOpen(false);
            navigate('/customers');
        } catch (error) {
            console.error("Error archiving customer: ", error);
        }
    };

    if (loading || customerLoading) {
        return (
            <General title="Customer Details">
                <LoadingIndicator />
            </General>
        );
    }

    if (!user) {
        return (
            <General title="Customer Details">
                <div className='flex flex-col w-full h-full items-center justify-center'>
                    <Typography variant='h4'>Please log in to view this</Typography>
                </div>
            </General>
        );
    }

    if (!customer) {
        return (
            <General title="Customer Details">
                <div className='flex flex-col w-full h-full items-center justify-center'>
                    <Typography variant='h4'>Customer not found</Typography>
                </div>
            </General>
        );
    }

    return (
        <General title="Customer Details">
            <div className='flex flex-col w-full px-4 sm:px-6'>
                <div className='bg-white shadow-md rounded px-4 sm:px-8 pt-6 pb-8 mb-4'>
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                        {/* Personal Information Section */}
                        <div className='space-y-4'>
                            <div>
                                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                                    Personal Information
                                </Typography>
                                <div className="space-y-4">
                                    <InfoField label="Customer ID" value={id} />
                                    <InfoField label="Name" value={`${customer.firstName} ${customer.lastName}`} />
                                    <InfoField label="Email" value={customer.email} />
                                    <InfoField label="Phone" value={customer.phone} />
                                </div>
                            </div>
                        </div>

                        {/* Address Information Section */}
                        <div className='space-y-4'>
                            <div>
                                <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                                    Address Information
                                </Typography>
                                <div className="space-y-4">
                                    <InfoField label="Street Address" value={customer.address} />
                                    <InfoField label="City" value={customer.city} />
                                    <InfoField label="State/Province" value={customer.state} />
                                    <InfoField label="ZIP/Postal Code" value={customer.zip} />
                                    <InfoField label="Country" value={customer.country} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Additional Information Section */}
                    <div className='mt-6'>
                        <Typography variant='h6' color='blue-gray' className="text-sm font-medium text-gray-500 mb-2">
                            Additional Information
                        </Typography>
                        <div className="space-y-4">
                            <InfoField label="Created Date" value={new Date(customer.createdAt).toLocaleString()} />
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className='flex flex-row items-center gap-2 mt-6'>
                        <Button 
                            onClick={() => navigate(`/invoices/new/${id}`)}
                            className="flex items-center gap-2"
                            variant="outlined"
                            color="black"
                        >
                            Create Invoice
                        </Button>
                        <Button 
                            onClick={handleEdit}
                            className="flex items-center gap-2"
                            variant="outlined"
                            color="black"
                        >
                            Edit Customer
                        </Button>
                        {!customer.archived && (
                            <Button 
                                onClick={handleArchive}
                                className="flex items-center gap-2"
                                variant="outlined"
                                color="black"
                            >
                                Archive Customer
                            </Button>
                        )}
                        <button
                            onClick={() => navigate('/customers')}
                            className="text-gray-600 underline cursor-pointer p-1"
                        >
                            Back to Customers
                        </button>
                    </div>
                </div>
            </div>

            {/* Update Archive Modal styling */}
            <div 
                className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ${archiveConfirmOpen ? '' : 'hidden'}`}
                onClick={() => setArchiveConfirmOpen(false)}
            >
                <div 
                    className="bg-white rounded-lg shadow-xl max-w-md w-full mx-4"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="p-6">
                        <Typography variant="h5" className="mb-4">Confirm Archive</Typography>
                        <Typography className="mb-4">
                            Are you sure you want to archive this customer? They will no longer appear in the main customer list.
                        </Typography>
                        <div className="flex justify-end gap-2">
                            <Button
                                onClick={() => setArchiveConfirmOpen(false)}
                                variant="text"
                                color="gray"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={confirmArchive}
                                variant="filled"
                                color="red"
                            >
                                Archive
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </General>
    );
}

const InfoField = ({ label, value }) => (
    <div className='space-y-1'>
        <Typography variant='small' color='blue-gray' className="font-medium">
            {label}
        </Typography>
        <Typography className="text-gray-700">
            {value || '-'}
        </Typography>
    </div>
);

export default Customer;
