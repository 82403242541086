import React, { useEffect } from 'react';
import './App.css';
import { RegistrationForm } from './components/RegistrationForm';
// import { Header } from './components/Header';
// import { Footer } from './components/Footer'
// import { EngineGenerator } from './components/EngineGenerator';
// import About from './components/About'
import Dashboard from './components/Dashboard';
import LoadingIndicator from './components/LoadingIndicator';
import { AuthProvider, useAuth } from './contexts/AuthContext';
// import { useLocation } from 'react-router-dom';

import './styles/globals.css';

function AppContent() {
  const { currentUser, loading } = useAuth();
  
  if (loading) {
    return (
      <div className='flex flex-col w-full h-full'>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className='flex flex-col w-full h-full'>
      {!currentUser && <RegistrationForm />}
      {currentUser && <Dashboard currentUser={currentUser} />}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
