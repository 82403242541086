import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Typography, Input, Button } from "@material-tailwind/react";
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  InputToolbox,
  AttachmentButton,
} from '@chatscope/chat-ui-kit-react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from '../FirebaseConfig';
import SpeechControl from './engines/SpeechControl';

const ChatSidebar = ({ isOpen, onClose, currentUser, systemMessage, sendMessage, chatMessages }) => {
  const [inputText, setInputText] = useState('');
  const [apiKey, setApiKey] = useState(null);
  const [enableAi, setEnableAi] = useState(() => {
    return localStorage.getItem('chatEnableAi') !== 'false';
  });

  const [messages, setMessages] = useState([
    {
      message: "Hey there! I'm Rico, your business companion. How can I assist you today?",
      sentTime: "just now",
      sender: "Rico",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const [messageCount, setMessageCount] = useState(() => {
    return parseInt(localStorage.getItem('chatMessageCount')) || 0;
  });

  const [lastResetDate, setLastResetDate] = useState(() => {
    return localStorage.getItem('chatLastResetDate') || new Date().toDateString();
  });

  const [latestResponse, setLatestResponse] = useState(null);

  const MESSAGE_LIMIT = 100;

  const [autoSpeak, setAutoSpeak] = useState(() => {
    return localStorage.getItem('chatAutoSpeak') === 'true';
  });

  const [spokenMessages, setSpokenMessages] = useState(new Set());

  const [isNewResponse, setIsNewResponse] = useState(false);

  useEffect(() => {
    if (chatMessages) {
      setMessages((prevMessages) => {
        // Create a Set of existing message identifiers
        const existingMessageIds = new Set(
          prevMessages.map(msg => `${msg.sender}-${msg.sentTime}-${msg.message}`)
        );

        // Filter out duplicate messages
        const newMessages = formatChatMessages(chatMessages).filter(msg => {
          const messageId = `${msg.sender}-${msg.sentTime}-${msg.message}`;
          return !existingMessageIds.has(messageId);
        });

        return [...prevMessages, ...newMessages];
      });
    }
  }, [chatMessages]);

  const formatChatMessages = (chatMessages) => {
    return chatMessages.map((message) => {
      return {
        message: message.message,
        sender: message.sender,
        sentTime: message.sentTime,
        email: message.email,
      };
    });
  };

  const fetchApiKey = useCallback(async () => {
    try {
      const functions = getFunctions(app);
      const getOpenAIConfig = httpsCallable(functions, 'getOpenAIConfig');
      const result = await getOpenAIConfig();
      if (result?.data?.apiKey) {
        setApiKey(result.data.apiKey);
      }
    } catch (error) {
      console.error("Error fetching OpenAI config:", error);
    }
  }, []);

  useEffect(() => {
    if (!apiKey) {
      fetchApiKey();
    }
  }, []);

  const checkAndResetDaily = () => {
    const today = new Date().toDateString();
    if (today !== lastResetDate) {
      setMessageCount(0);
      setLastResetDate(today);
      localStorage.setItem('chatMessageCount', '0');
      localStorage.setItem('chatLastResetDate', today);
    }
  };

  const handleSendRequest = async (message) => {
    // if (!enableAi) {
    //   setMessages(prev => [...prev, {
    //     message: "AI responses are currently disabled. Enable AI to chat with Rico.",
    //     sender: "Rico",
    //   }]);
    //   return;
    // }

    if (!apiKey) {
      setMessages(prev => [...prev, {
        message: "Chat service is currently unavailable. Please try again later.",
        sender: "Rico",
      }]);
      return;
    }

    checkAndResetDaily();
    if (messageCount >= MESSAGE_LIMIT) {
      setMessages(prev => [...prev, {
        message: `You've reached your daily message limit of ${MESSAGE_LIMIT}. Please try again tomorrow.`,
        sender: "Rico",
      }]);
      return;
    }

    const newMessage = {
      message,
      direction: 'outgoing',
      sender: currentUser.uid,
      email: currentUser.email,
      sentTime: new Date().toISOString(),
    };

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    localStorage.setItem('chatMessageCount', newCount.toString());
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    sendMessage(newMessage);

    if (enableAi) {
      setIsTyping(true);
      try {
        const response = await processMessageToChatGPT([...messages, newMessage]);
        const content = response.choices[0]?.message?.content;
        if (content) {
          const chatGPTResponse = {
            message: content,
            sender: "Rico",
          };
          setMessages((prevMessages) => [...prevMessages, chatGPTResponse]);
          setLatestResponse(content);
          setIsNewResponse(true);
        }
      } catch (error) {
        console.error("Error processing message:", error);
      } finally {
        setIsTyping(false);
      }
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    if (!apiKey) {
      throw new Error("API key is not available");
    }
    
    const apiMessages = chatMessages.map((messageObject) => {
      const role = messageObject.sender === "Rico" ? "assistant" : "user";
      return { role, content: messageObject.message };
    });

    const apiRequestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [
        { role: "system", content: "I'm a business owner using ChatGPT for to organize my business" },
        ...apiMessages,
      ],
    };

    try {
      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + apiKey,
          "Content-Type": "application/json",
          "Origin": window.location.origin,
        },
        mode: 'cors',
        body: JSON.stringify(apiRequestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
      throw error;
    }
  }

  const getLastRicoMessage = useMemo(() => {
    return messages.filter(m => m.sender === "Rico").slice(-1)[0]?.message || null;
  }, [messages]);

  useEffect(() => {
    console.log('Auto-speak effect triggered:', {
      autoSpeak,
      latestResponse,
      isNewResponse,
      isInSpokenMessages: spokenMessages.has(latestResponse)
    });

    if (autoSpeak && latestResponse && isNewResponse && !spokenMessages.has(latestResponse)) {
      const lastMessage = messages.filter(m => m.sender === "Rico").slice(-1)[0];
      console.log('Checking last message:', {
        lastMessage,
        latestResponse,
        isMatch: lastMessage?.message === latestResponse
      });

      if (lastMessage?.message === latestResponse) {
        console.log('Auto-speak conditions met, preparing to speak');
        setSpokenMessages(prev => new Set(prev).add(latestResponse));
        setIsNewResponse(false);
      }
    }
  }, [autoSpeak, latestResponse, spokenMessages, isNewResponse]);

  const renderMessages = useCallback((messages) => {
    return messages.map((message, i) => {
      const isNewMessage = message.message === latestResponse && isNewResponse;
      const senderName = message.sender === "Rico" ? "Rico" : message.email || "User";

      return (
        <Message 
          key={i}
          model={{
            ...message,
            direction: (message.sender === "Rico" || message.sender !== currentUser.uid) ? "incoming" : "outgoing",
            position: "normal"
          }}
        >
          <Message.Header sender={senderName} />
          {message.sender === "Rico" && (
            <Message.Footer>
              <SpeechControl 
                text={message.message}
                currentUser={currentUser}
                engineId="rico"
                data-message={message.message}
                onRef={autoSpeak && isNewMessage ? true : false}
              />
            </Message.Footer>
          )}
        </Message>
      );
    });
  }, [currentUser, latestResponse, autoSpeak, isNewResponse]);

  const typingIndicator = useMemo(() => (
    isTyping ? <TypingIndicator content="Rico is typing" /> : null
  ), [isTyping]);

  const handleAutoSpeakChange = (e) => {
    const isChecked = e.target.checked;
    setAutoSpeak(isChecked);
    localStorage.setItem('chatAutoSpeak', isChecked);
  };

  const handleAiToggle = (e) => {
    const isChecked = e.target.checked;
    setEnableAi(isChecked);
    localStorage.setItem('chatEnableAi', isChecked);
  };

  useEffect(() => {
    if (systemMessage) {
      setMessages(prevMessages => [...prevMessages, {
        message: systemMessage,
        sender: "Rico",
        sentTime: "just now"
      }]);
    }
  }, [systemMessage]);

  const handleFileUpload = (event) => {
    console.log('handleFileUpload triggered', event.target.files);
    const file = event.target.files[0];
    if (file) {
      console.log('upload the file to google cloud storage')
      // handleSendRequest(`I've uploaded a file: ${file.name}`);
    }
  };

  const handleAttachmentClick = useCallback((event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    console.log('handleAttachmentClick triggered');
    
    const input = document.createElement('input');
    input.type = 'file';
    input.style.display = 'none';
    document.body.appendChild(input);
    
    input.onchange = (e) => {
      handleFileUpload(e);
      document.body.removeChild(input);
    };
    
    input.click();
  }, []);

  return (
    <div className="flex flex-col h-[50vh] lg:h-[80vh] w-full bg-white z-[1001]">
      <div className="p-4">
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="enableAi"
              checked={enableAi}
              onChange={handleAiToggle}
              className="mr-2"
            />
            <label htmlFor="enableAi" className="text-sm text-gray-600">
              Enable AI responses
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="autoSpeak"
              checked={autoSpeak}
              onChange={handleAutoSpeakChange}
              className="mr-2"
            />
            <label htmlFor="autoSpeak" className="text-sm text-gray-600">
              Auto-speak responses
            </label>
          </div>
        </div>
      </div>

      <MainContainer className="flex-1 overflow-hidden flex flex-col">
        <ChatContainer className="flex-1 relative">
          <MessageList 
            scrollBehavior="smooth" 
            typingIndicator={typingIndicator}
            className="pb-16"
          >
            {renderMessages(messages)}
          </MessageList>
          <MessageInput 
            placeholder="Send a Message" 
            onSend={handleSendRequest}
            attachButton={true}
            onAttachClick={handleAttachmentClick}
          >
            <InputToolbox>
              <AttachmentButton
                onClick={handleAttachmentClick}
                tooltip="Attach a file"
                disabled={false}
              />
            </InputToolbox>
          </MessageInput>
        </ChatContainer>
      </MainContainer>
    </div>
  );
};

export default React.memo(ChatSidebar);
