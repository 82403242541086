import React from 'react';
import { Typography } from "@material-tailwind/react";
import { Cog6ToothIcon } from "@heroicons/react/24/solid";
import LoadingIndicator from '../LoadingIndicator';

export default function EngineStatsSection({ 
    myEngine,
    mapPositions,
    enginePhotos,
    calendarEvents,
    chatMessages,
    onFeatureClick,
    showContent,
    onToggleContent,
    setOpenSettingsDialog
}) {
    return (
        <>
            <section className="block sticky top-16 z-30 bg-gradient-to-b from-military-green-50 via-earth-brown-50 to-white transition-all duration-300 py-6 rounded-lg border border-military-green-200 mb-4 mx-4">
                <div className='text-center space-y-4'>
                    <Typography 
                        variant="paragraph" 
                        className="text-military-green-600 transition-all duration-300 text-lg"
                    >
                        {myEngine.name || ""}
                    </Typography>
                    <Typography 
                        variant="paragraph" 
                        className="text-military-green-600 transition-all duration-300 text-base"
                    >
                        {myEngine.purpose || ""}
                    </Typography>

                    <div className="flex justify-center items-center gap-4">
                        <button
                            onClick={onToggleContent}
                            className="text-military-green-600 hover:text-military-green-800"
                        >
                            {showContent ? '← Go to Overview' : '→ Go to App'}
                        </button>
                        <button
                            onClick={() => setOpenSettingsDialog(true)}
                            className="text-military-green-600 hover:text-military-green-800 flex items-center gap-2"
                        >
                            <Cog6ToothIcon className="h-5 w-5" />
                            <span>Settings</span>
                        </button>
                    </div>

                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 px-4">
                        {Object.keys(myEngine.features || {})
                            .map(feature => {
                                let count = 0;
                                let label = '';
                                
                                switch(feature) {
                                    case 'map':
                                        count = mapPositions.length;
                                        label = 'Locations';
                                        break;
                                    case 'camera':
                                        count = enginePhotos.length;
                                        label = 'Photos';
                                        break;
                                    case 'calendar':
                                        count = calendarEvents.length;
                                        label = 'Events';
                                        break;
                                    case 'chat':
                                        count = chatMessages.length;
                                        label = 'Messages';
                                        break;
                                }

                                return (
                                    <div 
                                        key={feature} 
                                        className='bg-white p-4 rounded-lg shadow-sm cursor-pointer hover:bg-military-green-50 transition-colors'
                                        onClick={() => onFeatureClick(feature)}
                                    >
                                        <Typography variant="h4" className="text-military-green-700">{count}</Typography>
                                        <Typography variant="small" className="text-military-green-600">{label}</Typography>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </section>
        </>
    );
} 