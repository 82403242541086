import React, { useState, useEffect } from 'react'
import {
    Typography,
    Input,
    Card,
} from "@material-tailwind/react";
import { EngineOverview } from './EngineOverview';


import RegularButton from './buttons/RegularButton';

const getDaysAgo = (createdAt) => {
    if (!createdAt || !createdAt.seconds) return 'Creation date unknown';
    
    const created = new Date(createdAt.seconds * 1000); // Convert seconds to milliseconds
    const now = new Date();
    const diffTime = Math.abs(now - created);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 1 ? '1 day ago' : `${diffDays} days ago`;
};

export default function EngineContent({ currentUser, engines }) {
    const [searchTerm, setSearchTerm] = useState('');

    const filteredEngines = engines ? engines.filter(engine =>
        engine && engine.name && engine.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).sort((a, b) => {
        // Calculate total activity for each engine
        const getTotalActivity = (engine) => {
            return (engine.positionsCount || 0) + 
                   (engine.images?.length || 0) + 
                   (engine.calendarEventsCount || 0) + 
                   (engine.messageCount || 0);
        };
        
        return getTotalActivity(b) - getTotalActivity(a); // Sort in descending order
    }) : [];

    useEffect(() => {
        console.log('Engines data:', engines);
    }, [engines]);

    // Calculate maximum activity across all engines
    const maxActivity = engines?.reduce((max, engine) => {
        const total = (engine.positionsCount || 0) + 
                     (engine.images?.length || 0) + 
                     (engine.calendarEventsCount || 0) + 
                     (engine.messageCount || 0);
        return Math.max(max, total);
    }, 0) || 1; // Default to 1 to avoid division by zero

    return (
        <div className='flex flex-col mt-2'>
            {/* <RegularButton filled={true} text="Create New Engine" url="/engines/new" /> */}
            {engines && engines.length > 0 && (
                <div className="mb-4 mt-4">
                    <Input
                        type="text"
                        label="Search engines"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            )}
            <Card className="h-full w-full">
                <div className="grid grid-cols-1 gap-4 p-4">
                    {filteredEngines.length === 0 && (
                        <div className="flex flex-col justify-center items-center h-full">
                            <EngineOverview />
                            <RegularButton filled={true} text="Create New Engine" url="/engines/new" />
                        </div>
                    )}
                    {filteredEngines.map((engine, index) => (
                        <a 
                            key={`engine-${engine.id}+${index}`}
                            href={'/engines/' + engine.id}
                            className="flex flex-col bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-pointer"
                        >
                            <div className="flex flex-col space-y-2">
                                <div className="flex items-center space-x-4">
                                    <div 
                                        className="w-8 h-8 rounded-full flex-shrink-0" 
                                        style={{ backgroundColor: engine.color || '#000000' }}
                                    />
                                    <div>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-bold text-base"
                                        >
                                            {engine.name}
                                        </Typography>
                                        <Typography 
                                            variant="small" 
                                            color="gray" 
                                            className="text-sm"
                                        >
                                            {engine.createdAt 
                                                ? `Created ${getDaysAgo(engine.createdAt)}`
                                                : 'Creation date unknown'}
                                        </Typography>
                                        <div className="grid grid-cols-2 sm:grid-cols-4 sm:flex sm:space-x-4 gap-y-1 gap-x-4 mt-2">
                                            <div className="flex items-center gap-1">
                                                <div className="w-2 h-2 rounded-full bg-blue-500"/>
                                                <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap">
                                                    {engine.positionsCount || 0} locations
                                                </Typography>
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <div className="w-2 h-2 rounded-full bg-green-500"/>
                                                <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap">
                                                    {engine.images?.length || 0} photos
                                                </Typography>
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <div className="w-2 h-2 rounded-full bg-orange-500"/>
                                                <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap">
                                                    {engine.calendarEventsCount || 0} events
                                                </Typography>
                                            </div>
                                            <div className="flex items-center gap-1">
                                                <div className="w-2 h-2 rounded-full bg-purple-500"/>
                                                <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap">
                                                    {engine.messageCount || 0} messages
                                                </Typography>
                                            </div>
                                            <div className="flex items-center gap-1 col-span-2 sm:col-span-1">
                                                <svg className="w-2 h-2" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="4" cy="4" r="4" className={(() => {
                                                        const total = (engine.positionsCount || 0) + 
                                                                     (engine.images?.length || 0) + 
                                                                     (engine.calendarEventsCount || 0) + 
                                                                     (engine.messageCount || 0);
                                                        const percentage = (total / maxActivity) * 100;
                                                        if (percentage > 66) return 'fill-green-600';
                                                        if (percentage > 33) return 'fill-yellow-600';
                                                        return 'fill-red-600';
                                                    })()} />
                                                </svg>
                                                <Typography variant="small" color="gray" className="text-xs sm:text-sm whitespace-nowrap flex items-center gap-1">
                                                    {(() => {
                                                        const total = (engine.positionsCount || 0) + 
                                                                     (engine.images?.length || 0) + 
                                                                     (engine.calendarEventsCount || 0) + 
                                                                     (engine.messageCount || 0);
                                                        const percentage = Math.round((total / maxActivity) * 100);
                                                        
                                                        if (percentage > 66) return (
                                                            <>
                                                                <i className="fas fa-check-circle text-green-600" />
                                                                <span className="text-gray-900">{percentage}% utilization</span>
                                                            </>
                                                        );
                                                        if (percentage > 33) return (
                                                            <>
                                                                <i className="fas fa-exclamation-circle text-yellow-600" />
                                                                <span className="text-gray-900">{percentage}% utilization</span>
                                                            </>
                                                        );
                                                        return (
                                                            <>
                                                                <i className="fas fa-exclamation-triangle text-red-600" />
                                                                <span className="text-gray-900">{percentage}% utilization</span>
                                                            </>
                                                        );
                                                    })()}
                                                </Typography>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </Card>
        </div>
    );
}
