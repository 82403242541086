import React from 'react';
import { Progress, List, ListItem, ListItemPrefix, Typography } from "@material-tailwind/react";
import { CheckCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/solid";

export default function NewUser() {
    // This could be managed by a context or props in a real app
    const progress = 25; // 25% for first step complete
    
    const steps = [
        { title: "Get started", completed: true, url: "/engines/new" },
        { title: "Create your first engine", completed: false, url: "/engines/new" },
        { title: "Send your first message", completed: false, url: "/engines/new" },
        { title: "Unlock all premium features", completed: false, url: "/subscription/new" }
    ];

    return (
        <div className="p-4 bg-white rounded-lg shadow-sm border border-blue-gray-50">
            <div className="mb-4">
                <div className="flex justify-between items-center mb-2">
                    <Typography variant="h6" color="blue-gray">
                        Onboarding Progress
                    </Typography>
                    <Typography variant="h6" color="blue-gray">
                        {progress}%
                    </Typography>
                </div>
                <Progress 
                    value={progress} 
                    size="lg" 
                    color="blue" 
                    className="border border-blue-gray-50"
                />
            </div>

            <List>
                {steps.map((step, index) => (
                    <ListItem key={index} className="group py-1.5">
                        <ListItemPrefix>
                            {step.completed ? (
                                <CheckCircleIcon className="h-5 w-5 text-blue-500" />
                            ) : (
                                <ArrowRightCircleIcon className="h-5 w-5 text-blue-gray-300" />
                            )}
                        </ListItemPrefix>
                        <Typography
                            onClick={() => {
                                if (step.url) {
                                    window.location.href = step.url;
                                }
                            }}
                            color={step.completed ? "blue-gray" : "blue-gray"}
                            className={`font-medium ${step.completed ? "" : "opacity-70"}`}
                        >
                            {step.title}
                        </Typography>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}
