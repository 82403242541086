import { Typography } from "@material-tailwind/react";
import { getAuth } from "firebase/auth";

export function Footer() {
  const auth = getAuth();
  const isLoggedIn = auth.currentUser;

  return (
    <footer className="relative bottom-0 left-0 right-0 bg-white flex w-full flex-col md:flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 text-center md:justify-between p-2 md:p-10 z-[1000]">
      <div className="w-full md:w-auto flex justify-center md:justify-start">
        <Typography color="blue-gray" className="font-normal text-sm opacity-60 whitespace-nowrap">
          &copy; 2024 BCP Solutions, LLC
        </Typography>
      </div>
      <div className="w-full md:w-auto flex justify-center md:justify-end">
        <ul className="flex flex-wrap items-center justify-center gap-y-2 gap-x-4">
          <li>
            <Typography
              as="a"
              href="/pricing"
              color="blue-gray"
              className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Pricing
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="/affiliates"
              color="blue-gray"
              className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Become a Partner
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="/about-us"
              color="blue-gray"
              className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              About Us
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="/license"
              color="blue-gray"
              className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              License
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="/privacy-policy"
              color="blue-gray"
              className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Privacy Policy
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="/contact"
              color="blue-gray"
              className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
            >
              Contact Us
            </Typography>
          </li>
          {isLoggedIn && (
            <li>
              <Typography
                as="a"
                href="/sign-out"
                color="blue-gray"
                className="font-normal text-sm transition-colors hover:text-blue-500 focus:text-blue-500"
              >
                Sign Out
              </Typography>
            </li>
          )}
        </ul>
      </div>
    </footer>
  );
}