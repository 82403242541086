import React, { useEffect, useState } from 'react'

// import { Button, Checkbox, Typography, Input, List, ListItem, Card, Carousel } from "@material-tailwind/react";
// import { getFirestore, collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { getDatabase, ref, get } from "firebase/database";

// import Header from './Header'
// import { Footer } from './Footer'
// import NavigationLinks from './NavigationLinks'
// import LoadingIndicator from './LoadingIndicator';
import General from '../layouts/General';
import EngineContent from './EngineContent';
import LoadingIndicator from './LoadingIndicator';

import { db, auth } from '../FirebaseConfig'
// import { FIRESTORE_PATHS, getCollectionPath } from '../config/firestore';
import { getMyEngines } from '../services/engineService';

const database = getDatabase();

const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

export default function Engines() {
    const [myEngines, setMyEngines] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUser(user);
                getMyEngines(user.uid).then((engines) => {
                    setMyEngines(engines);
                    setLoading(false);
                })
            } else {
                setCurrentUser(null);
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    return (
        <General title={`Engines (${myEngines.length})`}>
            {loading ? (
                <LoadingIndicator />
            ) : (
                <EngineContent currentUser={currentUser} engines={myEngines} />
            )}
        </General>
    )
}